import React from "react";
import { ssoRecruitmentLinkDatum } from "../entrypoints/participant_dashboard";
import App from "./app";
import Zendesk from "react-zendesk";
import { LANDING_PAGE_ZENDESK_SETTINGS, ZENDESK_KEY } from "./shared/zendesk_settings";

export default function LandingPage({
  school,
  envBanner,
}: {
  school?: {
    name: string;
    logo: string;
    isSsoEnabled: boolean;
    isSsoTestingEnabled: boolean;
    ssoConnection: string;
    id: string;
    shortName: string;
    ssoMenteeRecruitmentLinkData: ssoRecruitmentLinkDatum[];
    ssoMentorRecruitmentLinkData: ssoRecruitmentLinkDatum[];
  };
  envBanner?: string;
}) {
  // We are currently using the `App` component to drop the user onto the login page if they are not
  // authenticated into the app. This might look different once we move off of using rails views entirely.
  return (
    <App
      urlBase="/"
      pageName="Landing Page"
      school={school}
      envBanner={envBanner}
      reloadOnLogin={true}
    >
      <div></div>
      <Zendesk defer zendeskKey={ZENDESK_KEY} {...LANDING_PAGE_ZENDESK_SETTINGS} />
    </App>
  );
}
